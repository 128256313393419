
.page-about {
    max-width: 100%;
    overflow-x: hidden;
    .intro {
        width: 100%;
        height: 100vh;
        position: relative;
        display: grid;
        place-items: center start;
        .headline {
            padding-left: 100px;
            @media all and (max-width: $bp-md) {
                padding-left: 2rem !important;
            }
            font-size: 90px;
            line-height: 1.3
        }
    }
    .sub-title {
        font-size: 3.125rem;
        padding-bottom: 5rem;
    }
    .timeline {
        color: $color-elephant;
        padding: max(4rem, 2rem);
        max-width: 100%;
        overflow: hidden;
    }

    .moon-holder {
        position: absolute;
        top: 0;
        left: 0; //move this to the right
        right: 0;
        bottom: 0;
    }

    .moon-window {
        background-image: url(https://d172nc0scfm542.cloudfront.net/about/moon-window.jpg);
        background-size: cover;
        background-position: center center;
        position: absolute;
        clip-path: circle(100% at 50% 50%);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .moon-title, .moon-copy {
        opacity: 0;
        @media all and (max-width: $bp-md) {
            opacity: 1;
        }
    }

    .mc-copy > div {
        opacity: 0;
    }
    
    .moon-circle {
        position: relative;
        display: grid;
        place-items: center;
        @media all and (max-width: $bp-md) {
            display: none;
        }
    }

    .mc-icons {
        position: absolute;
        width: Min(var(--mc-icons-width), 500px);;
        height: Min(var(--mc-icons-width), 500px);;
        transform: rotate(0);
        opacity: 0;
    }

    

    .mc-copy-line-1, .mc-copy-line-2, .mc-copy-line-3, .mc-copy-line-4 {
        font-size: Min(2vw, 29px);
    }

    .mc-circle {
        position: absolute;
        top: 0%;
        left: 0%;
        width: Min(var(--mc-icons-width), 500px);
        height: Min(var(--mc-icons-width), 500px);
        background-image: url('https://d172nc0scfm542.cloudfront.net/about/closed-carbon-loop.png');
        background-size: cover;
    }

    .mc-circle-md {
        position: relative;
        width: 100%;
        height: auto;
        background-image: url('https://d172nc0scfm542.cloudfront.net/about/closed-carbon-loop.png');
        background-size: cover;
    }

    .team-blue {
        background-image: url('https://d172nc0scfm542.cloudfront.net/about/bg-clouds.jpg');
        background-size: cover;
        width: 100%;
        min-height: 100vh;
        padding-top: max(4rem, 2rem);
        padding-bottom: max(4rem, 2rem);
    }

    .team-orange{
        width: 100%;
        min-height: 100vh;
        padding-top: max(4rem, 2rem);
        padding-bottom: max(4rem, 2rem);
    }

    .orange-team-member {
        max-width: 300px;
    }

    .future {
        background-image: url('https://d172nc0scfm542.cloudfront.net/home/bg1c.png');
        background-size: cover;
        width: 100%;
        min-height: 100vh;
    }

    // TODO CLean this up
    @media all and (max-width: $bp-md) {
        .timeline {
            padding: 1rem !important;
        }
        .about-food {
            max-width: 200px;
        }
        .future {
            min-height: 25vh;
        }
    }
}

:root {
    --mc-icons-width: 40vw;
    --mc-icon-size: calc(var(--mc-icons-width) * .35);
    @media all and (max-width: $bp-md) {
        --mc-icons-width: 90vw;
    }
}