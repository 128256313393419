*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  overscroll-behavior: none;
}

body {
  background-color: $color-background;
  // https://developers.google.com/web/updates/2017/11/overscroll-behavior
  overscroll-behavior: none;
  // Still not implemented by safari https://bugs.webkit.org/show_bug.cgi?id=176454
  overscroll-behavior-y: none;
  font-family: EuclidCircularA-Regular, Arial, Helvetica, sans-serif;
  color: $color-elephant;
  overflow-x: hidden;
}

hr {
  border-style: solid;
}

@media all and (max-width: $bp-md) {

  html,
  body {
    // position: fixed;
    // overflow: hidden;
    // height: 100%;
    height: -webkit-max-content;
    width: 100vw;
    overflow-x: hidden;
  }

  #app {
    // -webkit-overflow-scrolling: touch;
    // height: 100vh;
    width: 100vw;
    overflow-y: auto;
  }
}

button {
  padding: 0;
  border: none;
  background: inherit;
  cursor: pointer;
  border-radius: 0;
  outline: none;
}

a {
  color: inherit;
  transition: color 0.3s, background 0.3s;
  outline: none;
  padding: 0;
  margin: 0;
}

strong {
  font-weight: 700;
}

.user-entered-html {
  a {
    font-weight: 400;
  }

  strong {
    font-weight: 700;
  }

  em,
  i {
    font-style: italic;
  }

  ul,
  ol {
    padding-left: 20px;

    ul,
    ol {
      padding-left: 20px;
    }
  }

  ol {
    list-style-type: decimal;
  }

  li {
    margin-bottom: 5px;
    padding-left: 10px;
    position: relative;
  }

  ul li::before {
    content: "\2014";
    position: absolute;
    left: -1rem;
    top: 0;
    height: 1rem;
    width: 1rem;
    // border: 1px solid $color-black;
    // background-color: $color-black;
    // border-radius: 0%;
  }

  ul ul li::before {
    background-color: transparent;
  }
}

.icon {
  stroke: white;
  fill: white;
}

.curp {
  cursor: pointer;
}

.anchrowrap {
  position: static;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.anchor span {
  padding-left: 3px;
  padding-right: 3px;
}

.anchor-bg {
  z-index: -10;
  display: none;
}

.anchor-offset {
  left: -1rem;

  @media all and (max-width: $bp-md) {
    left: 0;
    top: -0.5rem;
  }
}

.anchor-black-white {
  overflow: hidden;
  display: inline-block;

  .anchor-bg {
    z-index: 0;
    display: block;
    background-color: $color-black;
    transform: translateY(101%);
    transition: 0.1s ease-in-out;
  }

  .link-text {
    z-index: 0;
    position: relative;
    border-bottom: 3px solid $color-black;
  }
}

.anchor-black-white:hover {
  .anchor-bg {
    transform: translateY(0%);
    background-color: $color-black;
  }

  .link-text {
    color: $color-white;
    border-bottom: 3px solid $color-black;
  }
}

@media all and (max-width: $bp-md) {
  .anchor-black-white.disable-mobile {
    .link-text {
      z-index: 0;
      position: relative;
      border-bottom: none;
    }
  }

  .anchor-black-white.disable-mobile:hover {
    .anchor-bg {
      transform: translateY(101%);
      background-color: $color-white;
    }

    .link-text {
      color: $color-black;
    }
  }
}

.anchor-white-blue {
  overflow: hidden;
  display: inline-block;

  .anchor-bg {
    display: block;
    transform: translateY(101%);
    transition: 0.1s ease-in-out;
  }
}

.anchor-white-blue:hover {
  .anchor-bg {
    transform: translateY(0%);
  }

  div {
    color: $color-white;
  }
}

.anchor-white-black {
  overflow: hidden;
  display: inline-block;

  .anchor-bg {
    display: block;
    background-color: $color-white;
    transform: translateY(101%);
    transition: 0.1s ease-in-out;
  }

  .link-text {
    z-index: 0;
    position: relative;
    border-bottom: 3px solid $color-white;
  }
}

.anchor-white-black:hover {
  .anchor-bg {
    transform: translateY(0%);
  }

  .link-text {
    color: $color-black;
    border-bottom: 3px solid $color-white;
  }
}

.center-in-grid {
  display: grid;
  place-items: center;
  grid-template-columns: 100vw;
  grid-template-rows: 100vh;
}

.center-in-grid-1fr {
  display: grid;
  place-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.grid-cs {
  place-items: center start;
}

.grid-ce {
  place-items: center end;
}

.grid-cc {
  place-items: center center;
}

@media all and (max-width: $bp-md) {
  .grid-cc\@md {
    place-items: center center;
  }
}

.h-fit {
  height: fit-content;
}

.w-fit {
  width: fit-content;
}

@media all and (max-width: $bp-md) {
  .ReactModal__Content--after-open {
    height: 100%;
    min-height: -webkit-fill-available;
  }
}

.responsive {
  width: 100%;
  height: auto;
}

@media all and (max-width: $bp-md) {
  .responsive\@md {
    width: 100% !important;
    max-width: none !important;
    height: auto;
  }
}

.responsive-alt {
  width: 60vw;
  max-width: 61vw;
  height: auto;
}

.modal-max {
  max-width: 880px;
  @media all and (max-width: $bp-md) {
    max-width: unset;
  }
}

.mb-items-md > * {
  margin-bottom: 1.5rem;
}

.mb-items-lg > * {
  margin-bottom: 3rem;
}

@media all and (max-width: $bp-md) {
  .mb-items-md\@md > * {
    margin-bottom: 1.5rem;
  }
  
  .mb-items-lg\@md > * {
    margin-bottom: 3rem;
  }
}

select:required:invalid {
  color: $color-elephant;
}
option[value=""][disabled] {
  display: none;
}
option {
  color: $color-elephant;
}

:root {
  --select-border: #16494C;
  --select-focus: #16494C;
  --select-arrow: var(--select-border);
}


.select {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;

  select,
  &::after {
    grid-area: select;
  }
  
  cursor: pointer;
  

  // Custom arrow
  &:not(.select--multiple)::after {
    content: "";
    justify-self: end;
    width: 0.8em;
    height: 0.5em;
    background-color: var(--select-arrow);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    z-index: 999;
    margin-right: 1rem;
    cursor: pointer;
    pointer-events: none;
  }
}

// Interim solution until :focus-within has better support
select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid var(--select-focus);
  border-radius: inherit;
}

select[multiple] {
  padding-right: 0;

  /*
   * Safari will not reveal an option
   * unless the select height has room to 
   * show all of it
   * Firefox and Chrome allow showing 
   * a partial option
   */
  height: 6rem;

  option {
    white-space: normal;

    // Only affects Chrome
    outline-color: var(--select-focus);
  }

}

.select--disabled {
  cursor: not-allowed;
  background-color: #eee;
  background-image: linear-gradient(to top, #ddd, #eee 33%);
}

.li-disc {
  list-style-type: disc;
}

@media (max-width:800px) {
  .js-hero-text.t110vh {
    top: 16vh !important;
    opacity: 1 !important;
  }
}

@media (min-width:800px) {
  .carbon-negative-wrapper .carbon-negative-container .right {
  right: 0;
  top: 0;
}
@media (min-width:1500px) {
  video.js-air-protein-chicken {
    max-width: 80vw;
}
}
@media (min-width:1800px) {
  video.js-air-protein-chicken {
    max-width: 70vw;
}
}
#carbonVid {
  top: 0 !important;
  right: 0 !important;
  left:unset !important;
  width: 100% !important;
  max-height: unset !important
}

#carbonVid.trf-tr\(-10\%\,30\%\) {
  transform: none;
}

.carbon-negative-wrapper .carbon-negative-container .right video {
  margin-top: 0;
  margin-bottom: 0;
}
}