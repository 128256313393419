.footer {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
  .col-title {
    font-size: 1.5rem;
  }
  a {
    text-decoration: none;
    font-size: 1.125rem;
    font-family: EuclidCircularA-Light, Arial, Helvetica, sans-serif;
  }
  // .row {
  //   // padding-bottom: 3.75rem;
  // }
  .row > * > * {
    padding-bottom: 0.5rem;
  }
  .social > svg {
    margin-right: 1rem;
  }

  @media all and (max-width: $bp-md) {
    .row {
      padding-bottom: 0;
    }
    .row > * {
      padding-bottom: 0.5rem;
    }
  }

  .logo-size {
    max-width: 200px;
  }
}
