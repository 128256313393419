.section-3 {
	.section-3__bubble {
		transform: translate(-20%, 50%) translateZ(0);
		-webkit-transform : translate(-20%, 50%) translateZ(0);

		@media all and (max-width: $bp-sm) {
			transform: translateX(-50%) translateZ(0);
			-webkit-transform : translateX(-50%) translateZ(0);
		}
	}
}
#flowerVid {
	width: 200%;
	position: relative;
	left: -30%;
	top: -28%;
}

#carbonVid {
	width: 170%;
	position: relative;
	top: -29%;
	left: -34%;
	}
@media (max-width:800px) {
	.js-wavy-text-1.posa.w50\%.t0vh.l40vw.w90\%\@md.z1.l0\@md {
		// left: 2vw;
		// top: -4vw;
	}
	#carbonVid {
		position: relative;
		top: -150px;
		left: -16%;
		margin: 0;
		display: flex;
		width: 140%;
		max-height: unset !important;
	}
	#flowerVid {
		width: 200%;
		position: relative;
		left: -27vw;
		top: -20vw;
		margin-top: 0;
	}
	.js-snap-section-2-content.carbon-negative-container {
		display: flex !important;
		flex-direction: column-reverse;
		padding-top: 40px;
	}
	.air-protein-is-wrapper .air-protein-is-container, .take-less-time-wrapper .take-less-time-container {
		display: flex !important;
		flex-direction: column-reverse !important;
	}
}