// @import "./config/fonts";
@import "./config/variables";
@import "./config/breakpoints";
@import "./config/atomic-variables";
@import "./config/atomic-config";
@import "./config/functional-helpers-config";

@import "./config/reset";
@import "../../node_modules/atomic-scss/scss/atomic";
@import "../../node_modules/scss-functional-helpers/scss/functional-helpers";
@import "./site/functional-helpers-extensions";

// some default styling for elements
@import "./site/default";

// site wide styles
@import "./site/typography";

// global components (used on every page)
@import "./global/header";
@import "./global/footer";
@import "./global/logos";
@import "./global/page-container";
@import "./global/text-Input";
@import "./global/cta-button";
@import "./global/animations";
@import "./global/radio";

// blocks
@import "./blocks/grid";

// components (used on some pages)

// page/partial level css (used on a single page)
@import "./pages/home/section-3";
@import "./pages/about";
@import "./pages/resources";
@import "./pages/careers";
@import "./pages/newsroom";
@import "./pages/process";

.paddTop {
	padding-top: 100px;
}
.paddTop .aic {
	align-items: flex-end;
}
.split-2 {
	display: flex;
	width: 100%;
}

.page-home .home-section1-half {
	justify-content: flex-start;
}
.home-section1-half {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: calc(100vh - 120px);
  }
  .home-section1-half.zindex1 h1 {
	padding-left: 35px !important;
}
.home-section2 .home-section1-half {
	justify-content: center;
}
	.fuller img {
	display: block;
}
.makeIt {
	z-index: 999;
	position: relative;	
}
.q-name.c-white.fz3\.25rem.fz2rem\@md.posr {
	font-weight: 700;
	line-height: 1.3;
}
.home-section1-half.tuneIn h2 {
	font-size: 32px;
	font-weight: 700;
	margin-bottom: 20px;
	line-height: 46px;
}
.whiteOv, .whiteOv h1 {
	color: #16494C !important;
}
.home-section1-half img {
	width: 100%;
}  
.rightSet {
	padding-left: 100px;
  }
.rightSet .introText {
width: 100%;
}
.home-section1-half.rightSet h2 {
font-size: 32px;
font-weight: 700;
margin-bottom: 20px;
line-height: 35px;
}
.split-2.home-section1 {
margin-top: 120px;
}
.split-2.home-section1 h1 {
	font-size: clamp(28px, 4vw, 66px);
	font-weight: 700;
	line-height: 1.3;
}
.home-section1-half .introText {
	width: 80%;
}
tm {
	font-size: 20%;
	vertical-align: super;
}
.timeline-headline {
	font-size: 4rem;
	font-weight: 700;
	line-height: 1.3;
}
.statsTable {
	font-size: 18px;
}
.statsTable {
	font-size: 20px;
	margin-top: 50px;
	line-height: 34px;
}
.statsTable td {
	padding-right: 30px;
}
.guyJump {
	transform: scaleX(-1);
	position: relative;
	top: -3vw !important;
	left: 3vw !important;
	width: 40vw;
}
.whiter {
	background: #fff;
}
.topMarge {
	margin-top: 40px;
}
.swal2-popup.swal2-modal.swal2-show {
	width: 94vw !important;
}

  .swal2-popup.swal2-modal.swal2-show {
	padding: 0;
}
  .swal2-actions {
	position: absolute;
	bottom: 0;
	left: calc(50% - 100px);
	width: 200px;
	justify-content: center;
  }
  .swal2-actions {
	margin-bottom: 3vw;
}
  .swal2-image {
	display: block;
	padding: 0;
	margin: 0;
  }
.swal2-confirm.swal2-styled {
	background: none;
	font-size: 1.2em;
	border: none;
	box-shadow: none !important;
}
.swal2-confirm.swal2-styled:focus {
	border: none !important;
	box-shadow: none !important;
}
.swal2-actions {
	margin-bottom: 3vw;
  }
  .swal2-actions {
	left: calc(50% - 150px);
	width: 300px;
  }
.featured-press .content .card-wrapper {
	flex-wrap: wrap;
	justify-content: space-between;
  }
  
  .featured-press .content .card-wrapper .card {
	margin: 0 0 60px !important;
  }  
  .card-wrapper > a {
	width: 30% !important;
	flex: inherit !important;
}
.card-wrapper {
	width: 100% !important;
}
.carbon-negative-wrapper {
	padding: 2vw 0 4vw;
}
.full-height {
	min-height: 100vh;
}
.full-height .rightSet {
	color: #fff;
}
.rev-food {
	position: relative;
	// top: -6vw;
}
.responsive.revolution-hands {
	width: 34vw !important;
}
.page-process.posr .responsive.revolution-hands {
	position: relative;
	left: 60px;
}
.page-about .responsive.home-intro-pasta {
	width: 46vw !important;
}
.responsive.home-intro-pasta {
	width: 60vw !important;
	position: absolute;
	right: 0;
	z-index: 0;
}
.zindex1 {
	z-index: 1;
}
.grid.maw1400.posr.w100\%.pl100.pr100.ml-auto.mr-auto.split-2.home-section1.revolution.home-intro {
	padding-top: 100px;
	margin-top: 0;
}
.bullPin {
	list-style: disc;
	margin-left: 20px;
}
  .bullPin li {
	margin-bottom: 6px;
  }
  .topSpace {
	padding-top: 120px;
}
.bullPin.grasPin {
	margin-left: 30px;
}
.wideSet .introText {
	width: 100% !important;
}
.footLog {
	width: 120px;
}
.header .logo {
	width: 6.5rem;
	height: 2.25rem;
}
.header .logo img {
	width: 70px;
}
.flavorBox .tuneIn {
	padding-right: 80px !important;
}
.home-intro-h1.logoHome {
	font-size: 40px !important;
	color: #074f51;
}
.w55\%.introText.whiteText {
	color: #074f51;
}
.boxSky {
	margin-top: 70px;
}
.home-intro-h1.logoHome img {
	width: 80%;
}
.whiteLop {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100%;
	background: rgba(255,255,255,0.4);
}
.logo-inline svg {
	width: 200% !important;
	margin: 40px 0;
  }
  
  .logo-inline {
	width: 100% !important;
  }
  .panzer input.cta-outline {
	color: #16494C;
	border-color: #16494C;
}
.carbon-negative-wrapper .carbon-negative-container .left .description {
	font-size: 24px !important;
	line-height: 30px !important;
}
.js-snap-section-2.carbon-negative-wrapper #carbonVid {
	width: 100% !important;
	max-width: 640px !important;
}
	#carbonVid {
	  left: -2vw !important;
	}
	.rev-z {
		font-size: 4rem;
		font-weight: 700;
		line-height: 1.3;
	}
.rev-z-half {
	margin-bottom: max(6rem, 4rem);
	margin-top: max(4rem, 2rem);
  }
  .w80\%.w100\%\@md.paraAd {
	color: #fff;
	font-size: 22px;
	line-height: 1.3;
}  
.js-snap-section-2.carbon-negative-wrapper.backFix {
	min-height: auto;
	padding-bottom: 70px;
}

.home-intro-h1.logoHome {
	font-size: 32px !important;
	line-height: 46px;
  }
  .responsive.home-intro-logo {
	width: 320px;
	margin: 0 auto;
}
  .take-less-time-wrapper .take-less-time-container .right .heading, .carbon-negative-wrapper .carbon-negative-container .left .heading {
	line-height: 46px !important;
}
.featured-press .content .title {
	font-size: 36px !important;
}
.fz2rem, .fz2\.25rem {
	font-size: 32px !important;
	line-height: 46px !important;
}
.margT {
	margin-top: 40px;
}
.lbra {
	padding-top: 20px;
}
.opa {
	opacity: 0;
}
input.rounded::placeholder, .rounded::placeholder {
	color: #9D9D9D;
	font-size: 13px;
  }
  .dropdown-alt {
	color: #9D9D9D;
	font-size: 13px;
}
footnote {
	font-size: 11px;
}
.h75vh {
	min-height: 540px;
}

.featured-press .content .card-wrapper .card {
		width: 24% !important;
		margin: 0 3% 40px !important;
	  }
	  
	  .news-tile__thumbnail {
		width: 90%;
		margin: 5% auto 0;
	  }
	  .js-snap-section-2-content.carbon-negative-container.rowFix {
		flex-direction: row;
	}
	.js-snap-section-2.carbon-negative-wrapper.backFix, .tripleFermentation, .orangeBack {
		background: #ff8033;
	}
.js-snap-section-2-content.carbon-negative-container.rowFix {
	align-items: center;
  }
  .js-snap-section-2.carbon-negative-wrapper.backFix {
	padding-top: 0;
  }
  .responsive.fullScale {
	display: block;
}
.tripleWorld, .tripleFermentation {
	align-items: center;
	min-height: 100vh;
	display: flex;
	justify-content: center;
  }
  .tripleCopy {
	font-size: 1.5rem;
}
  .tripleWorld > div, .tripleFermentation > div {
	width: 33%;
  }
  .orangeLogo img {
	width: 90%;
}
.whiteLogo img {
	width: 90%;
}
.orangeBack .tripleCopy {
	color: #fff;
}
.lh16 {
	line-height: 1.6;
} 
.tripleCopy {
	font-size: 1.5rem;
	color: #fff;
	text-shadow: 0px 0px 5px #000;
  }
.tripleFermentation {
	justify-content: flex-end;
}
.half-earth, .half-fermentation {
	position: absolute;
}
.half-earth img, .half-fermentation img {
	width: 90%;
  }
  .half-earth, .half-fermentation {
	display: flex;
  }
  .half-earth {
	right: 0;
	justify-content: flex-end;
  }
  .half-fermentation {
	left: 0;
}  
.page-process .video-step-1 {
	width: 40vw !important;
	position: relative !important;
}
.js-two-up-4 img {
	width: 360px;
	height: 360px;
	object-fit: cover;
	border-radius: 100%;
	margin-left: 120px;
}
.tripleWorld {
background: rgb(0,0,0);
background: -moz-radial-gradient(100vw 60vw at center, rgba(0,0,0,0.7049020291710435) 0%, rgba(0,0,0,0) 42%, rgba(0,0,0,0) 87%);
background: -webkit-radial-gradient(100vw 60vw at center, rgba(0,0,0,0.7049020291710435) 0%, rgba(0,0,0,0) 42%, rgba(0,0,0,0) 87%);
background: radial-gradient(100vw 60vw at center, rgba(0,0,0,0.7049020291710435) 0%, rgba(0,0,0,0) 42%, rgba(0,0,0,0) 87%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}
@media (min-width:1400px) {
.page-process .step-headline {
	font-size: 32px !important;
	line-height: 46px !important;
}
}
@media (min-width:800px) {
	#carbonVid {
	top: 4vw !important;
}
}

.mobOnly {
	display: none !important;
}
.page-process.posr .rev-food, .page-about .split-2.home-section1 h1 {
	line-height: 1.2;
	margin-bottom: 20px;
}
.page-newsroom .p1\.5rem {
	padding: 0 2rem 0 0;
}
.page-newsroom video, .center {
	margin-top: 37px;
  }
.page-careers .quote-grid {
	place-items: flex-start center !important;
	padding-top: 80px !important;
  }
  
.page-careers .jcsb {
	justify-content: flex-start !important;
  }
  
  .topM {
	padding-top: 80px;
  }
@media (max-width: 768px) {
	.split-2 {
		flex-direction: column-reverse;
	}

	.js-two-up-4 img {
		width: 50vw;
		height: 50vw;
		object-fit: cover;
		border-radius: 100%;
		margin: 0 auto 40px;
		display: block;
	}
.page-process.posr .responsive.revolution-hands {
	left: 0;
  }
  .page-process .responsive.revolution-hands,.page-about .responsive.home-intro-pasta {
	width: 80vw !important;
} 
.page-about .intro {
	height: auto;
	padding: 120px 0 80px;
}
	.tripleWorld {
		background: black;
		background: -moz-radial-gradient(200vw 160vw at center, rgba(0, 0, 0, 0.7049020292) 0%, rgba(0, 0, 0, 0) 42%, rgba(0, 0, 0, 0) 87%);
		background: -webkit-radial-gradient(200vw 160vw at center, rgba(0, 0, 0, 0.7049020292) 0%, rgba(0, 0, 0, 0) 42%, rgba(0, 0, 0, 0) 87%);
		background: radial-gradient(200vw 160vw at center, rgba(0, 0, 0, 0.7049020292) 0%, rgba(0, 0, 0, 0) 42%, rgba(0, 0, 0, 0) 87%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
	}
	.tripleWorld {
	  flex-direction: column;
	}
	.tripleWorld > div {
	  width: 100%;
	}
	.half-earth {
	  display: none;
	}
	.tripleWorld {
	  height: auto;
	  min-height: unset;
	  padding-bottom: 60px;
	  padding-top: 120px;
		}	
	.tripleWorld > div {
	  margin-bottom: 40px;
	}
	.half-earth, .half-fermentation {
	  position: relative;
	}

	.tripleFermentation {
	  flex-direction: column;
	}
	
	.tripleFermentation > div {
	  width: 100%;
	}
	
	.half-fermentation {
	  display: none;
	}
	
	.tripleFermentation {
	  min-height: unset;
	  padding: 40px 2rem;
	}
	
	.mobOnly {
		display: block !important;
	}
	.js-snap-section-2-content.carbon-negative-container.rowFix {
		flex-direction: column;
	}

  .js-snap-section-2-content.carbon-negative-container.rowFix {
    flex-direction: column-reverse;
  }
  .carbon-negative-wrapper .carbon-negative-container .right {
    padding: 0 2rem !important;
  }
.js-snap-section-2-content.carbon-negative-container.rowFix .right img {
  margin-bottom: 0 !important;
}

	.deskOnly {
		display: none !important;
	}
	
		body .carbon-negative-wrapper .right #carbonVid {
		max-width: unset !important;
		left: 5vw !important;
		width: 90vw !important;
	}
	.quote-grid.w100\%.mih100vh.mb3rem\@md.mt3rem\@md.proof {
		padding-top: 100px;
	}
	.bullPin {
		list-style: disc;
		margin-left: 22px;
	}
	.redPad {
		padding: 0 3vw !important
	}
	.redPad .home-section1-half {
		padding: 8vw 0;
	}
	.home-section1-half.zindex0.noPadMob {
	  display: none;
	}
  .grid.maw1400.posr.w100\%.pl100.pr100.ml-auto.mr-auto.split-2.home-section2 {
	flex-direction: column-reverse;
  }
  .boxSky {
	background-size: 100vw auto !important;
	padding-top: 120px;
}
.founder-video__video-container {
	transform: translate(0%, 14%) !important;
  }
  .w8vw {
	width: 18vw;
  }
  .founder-video.h100\% svg.center {
	top: 11% !important;
  }
  
	.home-section1-half.zindex0.noPadMob {
		padding-top: 0;
	}
	.opa {
		display: none;
	}
	
.home-section1-half.tuneIn.perks1 {
	padding-bottom: 0 !important;
  }
  
  .home-section1-half.rightSet.perks2 {
	padding-top: 0;
  }
  
		.self-gras, .self-gras .pl100, .self-gras .pr100 {
	  padding-left: 20px !important;
	  padding-right: 20px !important;
	}
	.flavorBox .tuneIn {
		padding-right: 0px !important;
	}
	.self-gras {
	  background-position: right center !important;
	}

	.page-careers .guyJump {
		width: 90% !important;
		left: 5% !important;
	  }
	.potReduce.w100\%\@sm.mih100vh.mih100vh\@md.posr {
		  height: auto !important;
		}
.home-section1-half {
	width: 100%;
	min-height: unset;
	padding: 14vw 0;
  }
  
  .split-2 {
	flex-wrap: wrap;
  }
  .rev-wrap {
	padding: 0 0 80px !important;
}
.timeline-headline, .rev-food, .home-intro-h1, .rev-z {
  font-size: 44px !important;
  line-height: 1.3 !important;
}
.responsive.home-intro-pasta {
	width: 100% !important;
	position: relative;
	right: 0;
	z-index: 0;
}
.responsive.revolution-hands {
  width: 70vw !important;
  position: relative;
  margin: 0 auto;
  top: -8vw;
}
.rev-wrap {
    background-position: bottom center;
    background-size: 160% !important;
    background-repeat: no-repeat;
  }
.revolution > div {
  padding: 5vw 0 2vw;
}
.mob-order-1 {
	order:1
}
.mob-order-0 {
	order:0
}
}
@media (max-width:1100px) {
	.featured-press .content .card-wrapper .card {
		margin: 0 0% 40px !important;
		width: 100% !important;
	}
  }

  .astro-grid {
	max-width: unset;
	background: #ff8733;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
}
.astro-grid .c-white {
	color: #fff !important;
}
.astro-logo-size {
	width: 100%;
	max-width: 280px;
	margin-top: 60px;
}
.astro-grid .home-section1-half.rightSet h2 {
	margin-bottom: 10px;
}
.astro-grid .bigger {
	font-size: 26px;
	font-weight: 900;
}
.home-section1-half.rightSet.c-white.astro-text {
	width: 44%;
	padding-left: 40px;
}
.home-section1-half.astro-image {
	width: 56%;
}
.astro-grid a {
	text-decoration: none;
}
@media (max-width: 768px) {
	.astro-grid .home-section1-half {
		width: 100% !important;
		padding: 10vw 0 !important;
	}
	.astro-grid .grid {
		padding-left: 1rem;
		padding-right: 1rem;
	}
	.astro-grid {
		padding-top: 120px;
	}
	.astro-grid .bigger {
		font-size: 24px;
		white-space: nowrap;
	}
	.astro-logo-size {
		margin-bottom: 10px;
	}
}
