.section-3-new {
  font-family: EuclidCircularA-Regular;
  background: white;
  .content {
    .heading {
      max-width: 1400px;
      margin: auto;
      padding: 100px;
      padding-bottom: 40px;
      .sub-heading {
        font-weight: 600;
        font-size: 18px;
        line-height: 15px;
        text-transform: uppercase;
        color: #16494c;
        padding-bottom: 10px;
        color: #ff8733;
      }
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 40px;
      color: #16494c;
      @media (max-width: 800px) {
        padding: 2rem;
        padding-top: 3rem;
      }
    }
    .main-content {
      background: #16494c;
      background-image: url("https://d172nc0scfm542.cloudfront.net/home/bg3.png");
      background-repeat: no-repeat;
      background-size: contain;
      .content-wrapper {
        max-width: 1400px;
        display: flex;
        margin: auto;
      }
      .left {
        width: 49%;
        display: inline-block;
        padding: 50px 100px;
        .text {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 23px;
          color: #ffffff;
          font-family: EuclidCircularA-Light;
        }
        .btn {
          margin-top: 30px;
          background: #ffffff;
          border-radius: 16px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          color: #16494c;
          padding: 7px 14px;
          cursor: pointer;
          display: inline-block;
        }
      }
      .right {
        width: 49%;
        display: inline-block;
        padding: 0px 50px;
      }

      @media (max-width: 1100px) {
        .left {
          width: 100%;
          padding-right: 15px;
        }
        .right {
          padding-left: 15px;
          width: 100%;
        }
      }
      @media (max-width: 800px) {
        .content-wrapper {
          display: block;
          .left {
            padding: 2rem;
          }
          .right {
            margin-bottom: 30px;
            padding: 2rem;
            .founder-video__video-container {
              transform: unset !important;
              video {
                top: unset !important;
                left: unset !important;
                transform: unset !important;
              }
            }
          }
        }
      }
    }
  }
}

.air-protein-is-wrapper {
  font-family: EuclidCircularA-Regular;
  z-index: 10;
  color: #16494c;
  position: relative;
  background: white;
  background-image: url("https://d172nc0scfm542.cloudfront.net/home/bg4.png");
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: contain;
  .air-protein-is-container {
    max-width: 1400px;
    display: flex;
    margin: auto;
    .right {
      padding: 100px;
      padding-right: 15px;
      width: 49%;
      display: inline-block;
      .sub-heading {
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
        color: #16494c;
        font-family: EuclidCircularA-Regular;
      }
      .heading {
        color: #16494c;
        margin-top: 10px;
        font-size: 32px;
        line-height: 40px;
      }
      .menu-wrapper {
        margin-top: 50px;
        border-bottom: 3px solid #ff8033;
        display: flex;
        overflow: auto;
        .menu-item {
          flex: 1;
          text-align: center;
          font-size: 28px;
          line-height: 36px;
          color: #16494c;
          cursor: pointer;
          padding: 7px;
          &.active {
            background: #ff8033;
            color: white;
          }
        }
      }
      .description {
        margin-top: 50px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 23px;
      }
    }
    .left {
      width: 49%;
      display: inline-block;
      img {
        margin-top: 100px;
        width: 100%;
      }
    }
    @media (max-width: 1100px) {
      .right {
        width: 100%;
        padding-right: 15px;
      }
      .left {
        padding-left: 15px;
        width: 100%;
      }
    }
    @media (max-width: 1000px) {
      display: block;
      .right {
        padding: 100px;
      }
      .left {
        padding: 0px 100px;
      }
    }
  }
  @media (max-width: 800px) {
    .air-protein-is-container {
      display: block;
      .right {
        padding: 2rem;
        padding-bottom: 0px;
        .menu-wrapper {
          .menu-item {
            font-size: 19px;
            padding: 5px;
          }
        }
        .description {
          margin-top: 30px;
        }
      }
      .left {
        margin-bottom: 30px;
        padding: 2rem;
        padding-top: 0px;
      }
    }
  }
}
.carbon-negative-wrapper {
  font-family: EuclidCircularA-Regular;
  z-index: 10;
  color: #16494c;
  background: #16494c;
  position: relative;
  .carbon-negative-container {
    max-width: 1400px;
    display: flex;
    margin: auto;
    .left {
      padding: 100px;
      width: 49%;
      display: inline-block;
      .sub-heading {
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
        color: #ffffff;
        font-family: EuclidCircularA-Regular;
      }
      .heading {
        color: #ffffff;
        margin-top: 10px;
        font-size: 28px;
        line-height: 36px;
      }
      .description {
        margin-top: 25px;
        font-style: normal;
        font-weight: normal;
        color: #ffffff;
        font-size: 18px;
        line-height: 24px;
        font-family: EuclidCircularA-Light;
        b {
          font-weight: 600;
          font-size: 21px;
        }
      }
    }
    .right {
      width: 49%;
      display: inline-block;
      padding-left: 15px;
      img,
      video {
        margin-top: 50px;
        margin-bottom: 50px;
        width: 100%;
      }
    }
    @media (max-width: 1100px) {
      .left {
        width: 60%;
        padding-left: 15px;
      }
      .right {
        padding-right: 15px;
        width: 70%;
        left: 45%;
      }
    }
    @media (max-width: 1000px) {
      display: block;
      .left {
        padding: 60px 100px 100px 100px;
      }
      .right {
        padding: 0px 100px;
      }
    }
  }
  @media (max-width: 800px) {
    .carbon-negative-container {
      display: block;
      .right {
        padding: 2rem;
        padding-bottom: 0px;
        .menu-wrapper {
          .menu-item {
            font-size: 19px;
            padding: 5px;
          }
        }
        .description {
          margin-top: 30px;
        }
      }
      .left {
        margin-bottom: 30px;
        padding: 2rem;
        padding-top: 0px;
      }
    }
  }
}
.take-less-time-wrapper {
  font-family: EuclidCircularA-Regular;
  z-index: 10;
  color: white;
  background: #ff8033;
  position: relative;
  .take-less-time-container {
    max-width: 1400px;
    display: flex;
    margin: auto;
    .right {
      padding: 100px;
      width: 49%;
      display: inline-block;
      .sub-heading {
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
        color: #ffffff;
        font-family: EuclidCircularA-Regular;
      }
      .heading {
        color: #ffffff;
        margin-top: 10px;
        font-size: 28px;
        line-height: 36px;
      }
      .blocks {
        display: flex;
        .block {
          padding: 15px 27px;
          &.border {
            border: 1px solid #ffffff;
            border-radius: 40px;
          }
          &.extra-lm {
            margin-left: 23px;
          }

          .title-1 {
            font-size: 12px;
            line-height: 15px;
          }
          .title-2 {
            font-size: 21px;
            line-height: 26px;
            font-weight: 600;
            margin-top: 7px;
          }
        }
        &.mt-30 {
          margin-top: 30px;
        }
      }
    }
    .left {
      width: 49%;
      display: inline-block;
      padding-right: 15px;
      img,
      video {
        margin-top: 50px;
        margin-bottom: 50px;
        width: 100%;
      }
    }
    .description {
      margin-top: 25px;
      font-style: normal;
      font-weight: normal;
      color: #ffffff;
      font-size: 18px;
      line-height: 26px;
      font-family: EuclidCircularA-Light;
    }
    .btn-learn {
      margin-top: 50px;
      background: #ffffff;
      color: #ff8033;
      width: 233px;
      height: 45px;
      box-shadow: 0px 6px 14px rgba(22, 73, 76, 0.2);
      border-radius: 70px;
      font-weight: bold;
      font-size: 14px;
      line-height: 43px;
      text-align: center;
      color: #ff8033;
      cursor: pointer;
    }
    @media (max-width: 1100px) {
      .left {
        width: 100%;
        padding-left: 15px;
      }
      .right {
        padding-right: 15px;
        width: 100%;
      }
    }
    @media (max-width: 1000px) {
      display: block;
      .left {
        padding: 0px 100px 100px 100px;
      }
      .right {
        padding: 50px 100px 0px 100px;
      }
    }
  }
  @media (max-width: 800px) {
    .take-less-time-container {
      display: block;
      .right {
        padding: 2rem;
        padding-bottom: 0px;
        .description {
          margin-top: 30px;
        }
      }
      .left {
        margin-bottom: 0px;
        padding: 2rem;
        padding-top: 0px;
      }
    }
  }
}
.manifesto-video__video {
	width: 80vw;
	margin: 0 auto;
  height: auto;
}
.manifesto-video__video-container.posr.h100\% {
	width: 80%;
	margin: 0 auto;
  height: 45vw;
}

.page-about .section-3-new .content .main-content .left {
  height: 20vw;
}

.page-about .section-3-new .content .main-content {
  background-image: none;
  margin-bottom: 12vw;
}

.guyJump {
	transform: scaleX(-1);
	position: relative;
	top: -4vw;
	left: 1vw;
}
.js-manifesto-video-wrapper.posr.z1.h140vh.lh0.h-auto\@md {
	width: 80%;
	margin: 0 auto;
}
.news-tile__thumbnail {
	width: 100%;
	height: auto;
}
.page-home .botSlot {
	padding: 0 20px;
	align-items: flex-end;
}
.page-newsroom .news-tile__thumbnail {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.page-newsroom .news-tile {
  height: auto;
}
.blurb.fz1\.125rem.w100\% {
	padding: 10px 20px 20px;
}
.featured-press {
  z-index: 100;
  background: white;
  position: relative;
  .content {
    max-width: 1400px;
    margin: auto;
    padding: 100px;
    padding-bottom: 40px;
    z-index: 100;
    .title {
      margin-top: 10px;
      font-size: 40px;
      line-height: 46px;
      font-weight: 500;
    }
    .card-wrapper {
      display: flex;
      margin-top: 50px;
      margin-bottom: 50px;
      .card {
        background: #ffffff;
        opacity: 1;
        box-shadow: 0px 8px 20px rgba(34, 34, 34, 0.1);
        border-radius: 10px;
        flex: 1;
        order: 0;
        margin: 0px 20px;
        border: none;
        padding: 0 0 20px 0;
        height: auto;
        .name {
          font-weight: 600;
          font-size: 21px;
          line-height: 27px;
          color: #16494c;
          flex: none;
          order: 0;
          flex-grow: 1;
          margin: 10px 0px 0px;
          padding: 0 20px;
        }
        .read-more {
          font-weight: bold !important;
          min-width: 130px;
          text-align: right;
        }
      }
    }
    .btn-see-all {
      text-decoration: none;
      background: #ff8033;
      border-radius: 70px;
      height: 45px;
      width: 180px;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      line-height: 45px;
      color: #ffffff;
      cursor: pointer;
      margin: auto;
    }
    @media (max-width: 1100px) {
      .card-wrapper {
        display: block;
        .card {
          margin: 0px;
          margin-bottom: 20px;
        }
      }
    }
    @media (max-width: 800px) {
      padding: 2rem;
    }
  }
}

@media (max-width: 800px) {
  .w90\%\@md {
    width: 100% !important;
    left: 0% !important;
    top: 0% !important;
  }
  .wavePut {
      width: 100vw;
      height: 200px;
  }
}
.introText {
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	line-height: 30px;
	font-family: EuclidCircularA-Light;
  width: 48%;
}
.js-wavy-text-1.posa.w50\%.t0vh.l40vw.w90\%\@md.z1.l0\@md {
	opacity: 0;
}
.trotter {
	list-style: decimal;
	margin: 20px 20px 0;
}
.white-section-heading {
	color: #000;
	margin: 10px 0;
	font-size: 28px;
	line-height: 36px;
}
.js-snap-section-12.new-white-section-wrapper {
	padding-top: 80px;
}
.white-section-description {
	margin-top: 25px;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	font-family: EuclidCircularA-Light;
}
.take-less-time-wrapper .take-less-time-container {
	flex-direction: row;
}
.take-less-time-wrapper .take-less-time-container .right .blocks .block.extra-lm {
	margin-left: 7px;
}
.section-3-new .content .main-content {
  background-size: cover !important;
}
.air-protein-is-wrapper .air-protein-is-container .right .menu-wrapper .menu-item {
	font-size: 20px !important;
	line-height: 28px !important;
}
.founder-video.h100\% svg.center {
	top: 44%;
}

.iconBox {
  display: flex;
  width: 200%;
}

.iconBox img {
  width: 50% !important;
}
.js-snap-section-1.new-white-section-wrapper .heading {
	color: #16494c;
	margin-top: 10px;
	font-size: 32px;
	line-height: 40px;
}
.js-snap-section-1.new-white-section-wrapper .description {
  margin-top: 25px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  font-family: EuclidCircularA-Light;
}
.js-snap-section-1.new-white-section-wrapper {
	padding: 80px 0;
}
.js-snap-section-1-content.new-white-section-container {
  display: flex;
}

.js-snap-section-1-content.new-white-section-container .left, .js-snap-section-1-content.new-white-section-container .right {
  width: 50%;
  position: relative;
}
.js-snap-section-1-content.new-white-section-container video {
	width: 80%;
	margin: 0 10%;
}

.js-snap-section-1.new-white-section-wrapper {
	padding: 80px 0;
	font-family: EuclidCircularA-Regular;
}

.js-snap-section-1-content.new-white-section-container .right {
  padding: 100px;
  padding-right: 15px;
}


.js-snap-section-1-content.new-white-section-container .left, .js-snap-section-1-content.new-white-section-container .right {
  width: 49%;
}

.js-snap-section-1-content.new-white-section-container {
  max-width: 1400px;
  margin: 0 auto;
}
.carbon-negative-wrapper .carbon-negative-container .left .heading {
	color: #fff;
	margin-top: 10px;
	font-size: 32px;
	line-height: 36px;
}
.take-less-time-wrapper .take-less-time-container .right .heading {
	color: #ffffff;
	margin-top: 10px;
	font-size: 32px;
	line-height: 36px;
}
@media (min-width: 800px) {
  #carbonVid {
    width: 80% !important;
    max-width: 560px !important
  }
}

@media (max-width: 1100px) {
  .carbon-negative-wrapper .carbon-negative-container .right {
    padding-right: 15px;
    width: 60%;
    left: 50%;
  }
}

@media (max-width: 800px) {
  .carbon-negative-wrapper .carbon-negative-container .right {
    padding-right: 15px;
    width: 60%;
    left: 50%;
  }
  .iconBox {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .iconBox img {
    width: 100% !important;
  }

.js-snap-section-1-content.new-white-section-container {
  flex-wrap: wrap;
}

.js-snap-section-1-content.new-white-section-container .left, .js-snap-section-1-content.new-white-section-container .right {
  width: 100%;
  padding: 0 2rem;
}

}
.mobile-girl, #vidArqp {
  display: none;
}
@media (max-width:768px) {
  .spearator {
    border-bottom: 3px solid #16494c;
  }
#vidArqp {
  background: #fff;
  width: 100%;
  justify-content: center;
  display: flex;
}

#sepVid {
  width: 60vw;
}

  .mobile-girl {
    display: block;
    width: 40vw;
    position: absolute;
    z-index: 99;
    top: -10vw;
    left: 30vw;
  }
  .scallopMob {
    display: block !important;
  }
  .scallopMob {
    max-width: 60% !important;
    margin-bottom: 10vw;
  }
  .js-air-protein-chicken.posa.op0.t10vh.w90vw.l25vw.center-hori\@md.w100vw\@md.w150vw\@sm {
    margin-top: 250px !important;
  }
  .introText {
    width: 100% !important;
  }
  #carbonVid {
    position: relative;
    top: -150px;
    left: 8%;
    margin: 0;
    display: flex;
    width: 100%;
    max-height: unset !important;
  }
  .carbon-negative-wrapper .carbon-negative-container .right {
    padding-right: 15px;
    width: 100%;
    left: 0;
  }
  .carbon-negative-wrapper .carbon-negative-container .left {
    width: 100%;
  }
  .js-air-protein-chicken.posa.op0.t10vh.w90vw.l25vw.center-hori\@md.w100vw\@md.w150vw\@sm {
    top: auto !important;
  }
  .js-air-protein-chicken.posa.op0.t15vh.w35vw.center-hori.w50vw\@sm {
    bottom: 40px !important;
    top: auto !important;
    display: block !important;
  }
}

.carbon-negative-wrapper .carbon-negative-container .right img, .carbon-negative-wrapper .carbon-negative-container .right video {
  margin-top: 30px !important;
}
.revol {
  list-style: circle;
  margin-left: 20px;
}

.revol li {
  margin-bottom: 4px;
}
.featured-press .content .card-wrapper .card .name {
  word-spacing: 2px;
}
sup {
	font-size: 40%;
	vertical-align: super;
}
.splitRow {
  display: flex;
  align-items: center;
}

.split50 {
  width: 50%;
}
.h75vh {
	min-height: 490px !important;
}
.desktop-only {
  display: block;
}
.mobile-only {
  display: none;
}
h1.mobile-only {
	font-size: 40px;
	width: 100%;
	padding: 20px 40px;
	text-align: center;
}
.home-section1-half.rightSet h2 {
	color: #ff8a33;
}
.js-snap-section-2.carbon-negative-wrapper #carbonVid {
  max-width: 560px !important;
}
.page-contact .fz4rem {
	font-size: 4vw;
	margin-top: 40px;
}
@media (min-width: 800px) {
  #carbonVid {
    top: 2vw !important;
  }
}

@media (max-width:768px) {
  .page-contact .fz4rem {
    font-size: 12vw;
    margin-top: 0px;
  }
  .page-contact .w100\%.pl100.pr100.ml-auto.mr-auto.redPad {
    background: none !important;
  }
  .page-contact .redPad .home-section1-half {
    padding: 0vw 0;
  }
  .mobile-only.contactImg {
    margin-bottom: 30px;
  }
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: block;
  }
  .boxSky {
    padding-top: 0px;
  }
  .responsive.home-intro-logo {
    width: 50%;
    margin: 0 auto;
  }
  h1.mySize {
    font-size: 40px;
  }
}
  @media (max-width:900px) {
  .splitRow {
    flex-direction: column;
  }
  
  .split50 {
    width: 90%;
  }
  }