@use "sass:math";
.grid {
  &.grid--off {
    max-width: auto;
  }
  @media all and (max-width: $bp-md) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.row {
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
}

@for $i from 1 through 12 {
  .col-#{$i} {
    width: #{math.div(100%, 12) * $i};
  }
  @media all and (max-width: $bp-lg) {
    .col-#{$i}\@lg {
      width: #{math.div(100%, 12) * $i};
    }
  }

  @media all and (max-width: $bp-md) {
    .col-#{$i}\@md {
      width: #{math.div(100%, 12) * $i};
    }
  }

  @media all and (max-width: $bp-sm) {
    .col-#{$i}\@sm {
      width: #{math.div(100%, 12) * $i};
    }
  }
}