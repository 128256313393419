
.knockout-text {
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(255,255,255,0);
}

.trs-null {
  transform: translate3d(0,0,0);
}

.hide-nav {
  /* This timing applies on the way OUT */
  transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);

  /* Quick on the way out */
  transition: top 0.6s;

  top: -72px;
}

.show-nav {
  /* This timing applies on the way IN */
  transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);

  /* A litttttle slower on the way in */
  transition: top 0.6s;

  /* Move into place */
  top: 0;

}