.radio {
    font-size: 2.25rem;
    color: $color-crusta;
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 0.5em;
}

.radio__input {
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    display: flex;
    input {
        // ...existing styles
    
        &:checked + .radio__control {
          background: radial-gradient(currentcolor 50%, rgba(255, 0, 0, 0) 51%);
        }
      }
  }

  .radio__control {
    display: block;
    width: .5em;
    height: .5em;
    border-radius: 50%;
    border: 0.1em solid currentColor;
  }