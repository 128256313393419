input.rounded, textarea.rounded, .rounded {
    font-family: EuclidCircularA-Light, Arial, Helvetica, sans-serif;

    border: 2px solid #ccc;
     
    /* Safari 5, Chrome support border-radius without vendor prefix.
     * FF 3.0/3.5/3.6, Mobile Safari 4.0.4 require vendor prefix.
     * No support in Safari 3/4, IE 6/7/8, Opera 10.0.
     */
    -moz-border-radius: 1.5rem;
    -webkit-border-radius: 1.5rem;
    border-radius: 1.5rem;
    
    
    color: $color-elephant;
    
    /* with a big radius/font there needs to be padding left and right
     * otherwise the text is too close to the radius.
     * on a smaller radius/font it may not be necessary
     */
    padding: 0.6rem 1rem;
    
    /* only needed for webkit browsers which show a rectangular outline;
     * others do not do outline when radius used.
     * android browser still displays a big outline
     */
    outline: 0;
  
    /* this is needed for iOS devices otherwise a shadow/line appears at the
     * top of the input. depending on the ratio of radius to height it will
     * go all the way across the full width of the input and look really messy.
     * ensure the radius is no more than half the full height of the input, 
     * and the following is set, and everything will render well in iOS.
     */
    -webkit-appearance: none;

    width: 100%;
    max-width: 15.625rem;
  }

  input.rounded::placeholder, .rounded::placeholder {
    color: $color-gray;
  }

  .dropdown-alt {
    color: $color-gray;
  }

  input.rounded.alt {
    border-color: $color-elephant;
  }

  input.rounded.maxw, textarea.rounded.maxw, .rounded.maxw {
    max-width: none;
  }
  
  input.rounded:focus, textarea.rounded:focus, .rounded:focus {
    
    /* supported IE8+ and all other browsers tested.
     * optional, but gives the input focues when selected.
     * change to a color that suits your design.
     */
    border-color: $color-elephant;
    
  }

  @media all and (max-width: $bp-lg) {

  }

  @media all and (max-width: $bp-md) {
    input.rounded,textarea.rounded, .rounded {
      max-width: none;
    }
  }

  @media all and (max-width: $bp-sm) {
    input.rounded,textarea.rounded, .rounded {
      max-width: none;
    }
  }