.header {
  height: $height-header;

  .logo {
    width: 6.5rem;
    height: 2.25rem;
  }

  .nav a, .mobile-nav-list a {
    color: $color-elephant;
    text-decoration: none;
    font-family: EuclidCircularA-Light, Arial, Helvetica, sans-serif;
  }

  .active {
    position: relative;
  }

  .active::after {
    content: ' ';
    width: 3rem;
    height: 3px;
    background-color: $color-crusta;
    display: inline-block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -.5rem;
  }

  .mobile-nav-list {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    top: 0;
    left: 0;
    .links {
      padding-top: $height-header;
      clear: both;
      & > * {
        padding-bottom: 1.5rem;
        font-size: 1.5rem;
      }
    }
    .close-icon {
      margin-right: 1.6rem;
      margin-top: -.5rem;
    }
  }

  @media all and (max-width: $bp-lg) {
    height: $height-header-md;
  }

  @media all and (max-width: $bp-md) {
    height: $height-header-md;
  }

  @media all and (max-width: $bp-sm) {
    height: $height-header-sm;
  }
}
