.page-process {
    .air-woman {
        width: 98%;
        left: 1%;
        height: auto;
        top: 7vw;
    }
    .headlines {
        font-size: clamp(18px, 3.5vw, 48px);
    }
    .step-headline {
        font-size: clamp(18px, 3.5vw, 48px);
    }
    .change-header {
        font-size: clamp(32px, 5.5vw, 132px);
        line-height: 1.3;
    }
    .contact-button {
        width: 13.125rem;
    }
    // .video-step {
        
    // }
    .video-step-1 {
        width: 50vw;
    }
    .video-step-2 {
        width: 70vw; 
        z-index: -1;
    }
    .video-step-3 {
        width: 70vw; 
    }
    .video-step-4 {
        width: 55vw; 
    }
    @media screen and (max-width: $bp-md) {
        .js-wavy-text-1 {
            width: 105vw;
        }
        .js-wavy-text-2 {
            top: 68.5vh;
            left: -20vw;
            width: 135vw;
        }
        .un-center-vert\@md {
            top: unset;
            transform: unset;
        }
        .un-center\@md {
            top: unset;
            left: unset;
            transform: unset;
        }
        .air-woman {
            transform: unset;
            
        }
        .headlines {
            font-size: 2rem;
        }
        .step-headline {
            font-size: 1.5rem;
        }
    }

    @media screen and (min-width: $bp-lgx) {
        .js-cloud-section-1 {
            background-size: cover;
        }
        .un-center-vert\@lgx-mn {
            top: unset;
            transform: unset;
        }
        .un-center\@lgx-mn {
            top: unset;
            left: unset;
            transform: unset;
        }
        .air-clouds {
            text-align: center;
        }
        .air-woman {
            transform: unset;
            position: relative;
            left: unset;
            width: auto;
            height: 88%;
        }
        .headlines {
            font-size: 4rem;
        }
        .step-headline {
            font-size: 2.5rem;
        }
        .step-copy {
            font-size: 1.5rem;
        }
        .step-number {
            max-width: 8rem;
        }
        .video-step {
            max-width: 900px;
            position: relative;
        }
        .video-step-1 {
            width: 30vw;
        }
        .video-step-2 {
            width: 70vw; 
            z-index: 1;
        }
        .video-step-3 {
            width: 75vw;
            max-width: 1000px;
            position: absolute;
        }
        .video-step-4 {
            width: 55vw; 
        }
        .change-header {
            font-size: 4rem;
            line-height: 1.3;
        }
        .contact-button {
            width: 26rem;
            font-size: 1.25rem;
        }
    }
    @media screen and (min-width: $bp-lgxx) {
        .video-step-1 {
            width: 25vw;
        }
    }
}
.chicky {
	width: 45vw;
    top: 0;
}
.chucky {
	top: 30%;
}
@media screen and (max-width:800px) {
    .air-clouds {
        min-height: 74vw !important;
        height: 74vw !important;
    }
    .page-process .air-woman {
        width: 90%;
    }
}