.page-newsroom {
    .news-layout {
        display: grid;
        grid-template-rows: 5rem auto 1rem;
    }
    .article-layout {
        display: grid;
        grid-template-rows: auto 1rem;
    }
    .article-layout, .news-layout {
        cursor: pointer;
        pointer-events: auto;
    }
    .news-layout:hover {
        background-color: rgba($color: #ffffff, $alpha: .50);
        border-radius: 1rem;
    }
    .article-layout:hover {
        background-color: rgba($color: $color-crusta, $alpha: .25);
        border-radius: 1rem;
    }
    .articles {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        @media screen and (max-width: $bp-md) {
            grid-template-columns: unset;
            grid-auto-flow: row;
        }
    }

    @media screen and (max-width: $bp-md) {
        .mr0i {
            margin-right: 0 !important;
        }
    }
}