// wire framing colors
$color-crusta        : #FF8733;
$color-plantation    : #24484B;
$color-plantation-video : #175357;
$color-elephant      : #16494C;
$color-white         : #ffffff;
$color-pure-black    : #000000;

$color-black         : #101010;
$color-lightergray   : #E5E5E5;
$color-lightgray     : #D8D8D8;
$color-gray          : #9D9D9D;
$color-darkgray      : #7C7C7C;

// width of site's content
$width-wrapper      : 1400px;
$width-wrapper-wide : 1280px;

// the footer is always supposed to be on the bottom of the page, unless the
// content naturally pushes the footer below the fold. However, some content
// doesn't add to the document height, and there can be cases where the footer
// will appear above other content when the browser has a small view height.
//
// When a browsers height is less than this variable, then we need to force the
// footer below the bottom of the screen so everything will fit the following
// pages/components determine this cutoff as necessary:
//   home page, navigation
$height-cutoff-point: 750px;

$padding-grid-side: 20px;
$padding-grid-container: 0 $padding-grid-side;

// heights/widths
$height-header   : 65px;
$height-header-md: 65px;
$height-header-sm: 65px;
$height-footer   : 122px;
$height-footer-md: 122px;
$height-footer-sm: 122px;

$min-height-site   : $height-cutoff-point;
$min-height-site-sm: $height-cutoff-point + 400px;

// site colors - often named using http://chir.ag/projects/name-that-color
$color-background : $color-white;

// transparent
$color-black-transparent: rgba(#000, .25);

//$color- #ce1a37;
//$color- #db2c59;
// nav colors purple, brass, dark-blue, bright-red, neon-yellow, almond, mid-blue

$font-size-base : 16px;
