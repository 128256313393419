
@font-face {
  font-family: EuclidCircularA-Regular;
  src: url(https://d172nc0scfm542.cloudfront.net/fonts/EuclidCircularA-Regular.woff2);
  font-display: swap;
}

@font-face {
  font-family: EuclidCircularA-Light;
  src: url(https://d172nc0scfm542.cloudfront.net/fonts/EuclidCircularA-Light.woff2);
  font-display: swap;
}

html {
  font-size: $font-size-base;
}

body {
  font-family: EuclidCircularA-Light, Arial, Helvetica, sans-serif;
  font-size: $font-size-base;
  line-height: 1.33;
}
