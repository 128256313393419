.page-careers {
    background-color: $color-crusta;
    .quote-grid {
        display: grid;
        grid-auto-flow: column;
        place-items: center center;
        gap: 5rem;
        @media screen and (max-width: $bp-md) {
            grid-auto-flow: row;
            .quote-1 {
                align-self: flex-end;
            }
            .quote-2 {
                align-self: flex-start;
            }
        }
    }
}
.guyJump {
	transform: none !important;
	position: relative;
	top: -4vw;
	left: 1vw;
}
@media (max-width:800px) {
    .potReduce.w100\%\@sm.mih100vh.mih100vh\@md.posr {
        height: 120vw;
        min-height: 0;
    }
    .guyJump {
        width: 90%;
        left: 5%;
    }
}